// src/pages/Dashboard.js

import React, { useEffect, useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';
import TransactionForm from '../components/TransactionForm';

const Dashboard = () => {
  const navigate = useNavigate();
  
  const [transactions, setTransactions] = useState([]);
  const [stats, setStats] = useState({
    total_buy: 0.0,
    total_sell: 0.0,
    profit_loss: 0.0
  });

  const handleLogout = async () => {
    try {
      const response = await fetch('https://apimarco.ddns.net/api/logout', { // 請確認後端地址
        method: 'POST',
        credentials: 'include' // 攜帶 Cookie
      });

      const data = await response.json();

      if (response.ok) {
        // 登出成功，導航到登入頁面
        navigate('/login');
      } else {
        alert(data.msg || '登出失敗');
      }
    } catch (error) {
      console.error('登出錯誤:', error);
      alert('登出時發生錯誤');
    }
  };

  const fetchTransactions = async () => {
    try {
      const response = await fetch('https://apimarco.ddns.net/api/transactions', { // 請確認後端地址
        method: 'GET',
        credentials: 'include' // 攜帶 Cookie
      });

      const data = await response.json();

      if (response.ok) {
        setTransactions(data);
      } else {
        alert(data.msg || '獲取交易記錄失敗');
      }
    } catch (error) {
      console.error('獲取交易記錄錯誤:', error);
      alert('獲取交易記錄時發生錯誤');
    }
  };


  useEffect(() => {
  

    const fetchStats = async () => {
      try {
        const response = await fetch('https://apimarco.ddns.net/api/stats', { // 請確認後端地址
          method: 'GET',
          credentials: 'include' // 攜帶 Cookie
        });

        const data = await response.json();

        if (response.ok) {
          setStats(data);
        } else {
          alert(data.msg || '獲取統計數據失敗');
        }
      } catch (error) {
        console.error('獲取統計數據錯誤:', error);
        alert('獲取統計數據時發生錯誤');
      }
    };

    fetchTransactions();
    fetchStats();
  }, []);


  return (
    <div>
      <h1>儀表板</h1>
      <button onClick={handleLogout}>登出</button>
      <p>
        <Link to="/profile">查看用戶資料</Link>
      </p>
      <div className="mb-6">
          <TransactionForm onAdd={fetchTransactions} />
        </div>
      <h2>統計數據</h2>
      <p><strong>購買總成本:</strong> {stats.total_buy.toFixed(2)} 元</p>
      <p><strong>賣出總收益:</strong> {stats.total_sell.toFixed(2)} 元</p>
      <p><strong>利潤/損失:</strong> {stats.profit_loss.toFixed(2)} 元</p>

      <h2>您的交易記錄</h2>
      {transactions.length > 0 ? (
        <table border="1">
          <thead>
            <tr>
              <th>ID</th>
              <th>類型</th>
              <th>股票代號</th>
              <th>股數</th>
              <th>價格</th>
              <th>總金額</th>
              <th>手續費</th>
              <th>日期</th>
            </tr>
          </thead>
          <tbody>
            {transactions.map((tx) => (
              <tr key={tx.id}>
                <td>{tx.id}</td>
                <td>{tx.type}</td>
                <td>{tx.symbol}</td>
                <td>{tx.shares}</td>
                <td>{tx.price.toFixed(2)}</td>
                <td>{tx.total_amount.toFixed(2)}</td>
                <td>{tx.commission.toFixed(2)}</td>
                <td>{new Date(tx.date).toLocaleString()}</td>
              </tr>
            ))}
          </tbody>
        </table>
      ) : (
        <p>暫無交易記錄。</p>
      )}
    </div>
  );
};

export default Dashboard;
