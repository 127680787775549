// src/Router.js

import React from 'react';
import { Routes, Route, Navigate } from 'react-router-dom';
import Home from './pages/Home';
import Login from './pages/Login';
import Register from './pages/Register';
import Dashboard from './pages/Dashboard';
import Profile from './pages/Profile';
import ProtectedRoute from './components/ProtectedRoute';

const AppRouter = () => {
  return (
    <Routes>
      <Route path="/" element={<Home />} />
      <Route path="/login" element={<Login />} />
      <Route path="/register" element={<Register />} />
      
      {/* 使用 ProtectedRoute 保護 Dashboard 頁面 */}
      <Route
        path="/dashboard"
        element={
     
            <Dashboard />
       
        }
      />
      
      {/* 使用 ProtectedRoute 保護 Profile 頁面 */}
      <Route
        path="/profile"
        element={
   
            <Profile />

        }
      />
      
      {/* 重新導向未知路徑到 404 頁面 */}
      <Route path="*" element={<Navigate to="/404" replace />} />
      
      {/* 404 頁面 */}
      <Route path="/404" element={<h1>404 Not Found</h1>} />
    </Routes>
  );
};

export default AppRouter;
