// src/pages/Profile.js

import React, { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

const Profile = () => {
  const navigate = useNavigate();
  const [profile, setProfile] = useState(null);
  const [commission, setCommission] = useState('');

  const handleLogout = async () => {
    try {
      const response = await fetch('https://apimarco.ddns.net/api/logout', { // 請確認後端地址
        method: 'POST',
        credentials: 'include' // 攜帶 Cookie
      });

      const data = await response.json();

      if (response.ok) {
        // 登出成功，導航到登入頁面
        navigate('/login');
      } else {
        alert(data.msg || '登出失敗');
      }
    } catch (error) {
      console.error('登出錯誤:', error);
      alert('登出時發生錯誤');
    }
  };

  useEffect(() => {
    const fetchProfile = async () => {
      try {
        const response = await fetch('https://apimarco.ddns.net/api/profile', { // 請確認後端地址
          method: 'GET',
          credentials: 'include' // 攜帶 Cookie
        });

        const data = await response.json();

        if (response.ok) {
          setProfile(data);
          setCommission(data.commission_percentage);
        } else {
          alert(data.msg || '獲取用戶資訊失敗');
          if (response.status === 401 || response.status === 403) {
            // 未授權，導航到登入頁面
            navigate('/login');
          }
        }
      } catch (error) {
        console.error('獲取用戶資訊錯誤:', error);
        alert('獲取用戶資訊時發生錯誤');
      }
    };

    fetchProfile();
  }, [navigate]);

  const handleCommissionChange = (e) => {
    setCommission(e.target.value);
  };

  const handleCommissionSubmit = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://apimarco.ddns.net/api/set_commission', { // 請確認後端地址
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include', // 攜帶 Cookie
        body: JSON.stringify({ commission_percentage: commission })
      });

      const data = await response.json();

      if (response.ok) {
        alert('手續費百分比已更新');
        // 重新獲取用戶資料以顯示最新的手續費
        const profileResponse = await fetch('https://apimarco.ddns.net/api/profile', { // 請確認後端地址
          method: 'GET',
          credentials: 'include'
        });
        const profileData = await profileResponse.json();
        if (profileResponse.ok) {
          setProfile(profileData);
        }
      } else {
        alert(data.msg || '更新手續費百分比失敗');
      }
    } catch (error) {
      console.error('更新手續費百分比錯誤:', error);
      alert('更新手續費百分比時發生錯誤');
    }
  };

  return (
    <div>
      <h1>用戶資料</h1>
      {profile ? (
        <div>
          <p><strong>用戶名稱:</strong> {profile.username}</p>
          <p><strong>註冊日期:</strong> {new Date(profile.created_at).toLocaleString()}</p>
          <p><strong>手續費百分比:</strong> {profile.commission_percentage}%</p>
          
          <form onSubmit={handleCommissionSubmit}>
            <label>
              更新手續費百分比:
              <input
                type="number"
                step="0.01"
                value={commission}
                onChange={handleCommissionChange}
                required
                min="0"
              />%
            </label>
            <button type="submit">更新</button>
          </form>
        </div>
      ) : (
        <p>正在載入用戶資料...</p>
      )}
      <button onClick={handleLogout}>登出</button>
    </div>
  );
};

export default Profile;
