// src/pages/Login.js

import React, { useState } from 'react';
import { useNavigate, Link } from 'react-router-dom';

const Login = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const navigate = useNavigate();

  const handleLogin = async (e) => {
    e.preventDefault();

    try {
      const response = await fetch('https://apimarco.ddns.net/api/login', { // 請確認後端地址
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        credentials: 'include', // 攜帶 Cookie
        body: JSON.stringify({ username, password })
      });

      const data = await response.json();
      console.log(data);
      console.log(response.ok);
      if (response.ok) {
        console.log('登入成功');
        navigate('/dashboard'); // 登入成功，導航到 Dashboard
      } else {
        alert(data.msg || '登入失敗');
      }
    } catch (error) {
      console.error('登入錯誤:', error);
      alert('登入時發生錯誤');
    }
  };

  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <h1 className="text-3xl md:text-4xl font-bold mb-8 text-gray-800">登入</h1>
      <form 
        onSubmit={handleLogin} 
        className="bg-white shadow-md rounded-lg p-8 w-full max-w-md"
      >
        <div className="mb-6">
          <label className="block text-gray-700 font-semibold mb-2">用戶名稱:</label>
          <input
            type="text"
            value={username}
            onChange={(e) => setUsername(e.target.value)}
            required
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
          />
        </div>
        <div className="mb-6">
          <label className="block text-gray-700 font-semibold mb-2">密碼:</label>
          <input
            type="password"
            value={password}
            onChange={(e) => setPassword(e.target.value)}
            required
            className="w-full px-4 py-2 border border-gray-300 rounded-lg focus:outline-none focus:border-blue-500"
          />
        </div>
        <button
          type="submit"
          className="w-full bg-blue-500 text-white py-2 rounded-lg hover:bg-blue-600 transition duration-300"
        >
          登入
        </button>
      </form>
      <p className="mt-6 text-gray-600">
        還沒有帳號？{' '}
        <Link to="/register" className="text-blue-500 hover:underline">
          註冊
        </Link>
      </p>
    </div>
  );
};

export default Login;
