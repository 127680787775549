// src/pages/Home.js

import React from 'react';
import { Link } from 'react-router-dom';

const Home = () => {
  return (
    <div className="flex flex-col items-center justify-center min-h-screen bg-gray-100">
      <h1 className="text-3xl md:text-5xl font-bold text-gray-800 mb-6 text-center">
        歡迎來到股票交易統計系統
      </h1>
      <p className="text-lg md:text-xl text-gray-600 mb-4 text-center">
        這是主頁內容。
      </p>
      <div className="flex space-x-4">
        <Link
          to="/login"
          className="bg-blue-500 text-white px-6 py-2 rounded-lg shadow-md hover:bg-blue-600 transition duration-300"
        >
          登入
        </Link>
        <Link
          to="/register"
          className="bg-green-500 text-white px-6 py-2 rounded-lg shadow-md hover:bg-green-600 transition duration-300"
        >
          註冊
        </Link>
      </div>
    </div>
  );
};

export default Home;
