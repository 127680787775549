// frontend/src/components/TransactionForm.jsx
import React, { useState } from 'react';
import axios from 'axios';

const TransactionForm = ({ onAdd }) => {
  const [type, setType] = useState('buy');
  const [symbol, setSymbol] = useState('');
  const [shares, setShares] = useState('');
  const [price, setPrice] = useState('');
  const [error, setError] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const token = localStorage.getItem('token');
    try {
      const response = await fetch('https://apimarco.ddns.net/api/inserttransactions', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
          'Authorization': `Bearer ${token}`,
        },
        body: JSON.stringify({
          type,
          symbol,
          shares: parseInt(shares),
          price: parseFloat(price),
        }),
      });
  
      if (!response.ok) {
        const errorData = await response.json();
        throw new Error(errorData.msg || '新增失敗');
      }
  
      setSymbol('');
      setShares('');
      setPrice('');
      setError('');
      onAdd();
    } catch (err) {
      setError(err.message);
    }
  };
  

  return (
    <form onSubmit={handleSubmit} className="bg-white p-4 rounded shadow-md">
      <h2 className="text-xl font-semibold mb-4">新增交易記錄</h2>
      {error && <div className="mb-4 text-red-500">{error}</div>}
      <div className="flex flex-col sm:flex-row sm:space-x-4">
        <div className="mb-4 sm:mb-0">
          <label className="block text-gray-700">類型</label>
          <select
            value={type}
            onChange={(e) => setType(e.target.value)}
            className="w-full px-3 py-2 border rounded mt-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
            required
          >
            <option value="buy">買進</option>
            <option value="sell">賣出</option>
          </select>
        </div>
        <div className="mb-4 sm:mb-0">
          <label className="block text-gray-700">股票代碼</label>
          <input
            type="text"
            className="w-full px-3 py-2 border rounded mt-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={symbol}
            onChange={(e) => setSymbol(e.target.value)}
            required
            placeholder="例如: AAPL"
          />
        </div>
        <div className="mb-4 sm:mb-0">
          <label className="block text-gray-700">股數</label>
          <input
            type="number"
            className="w-full px-3 py-2 border rounded mt-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={shares}
            onChange={(e) => setShares(e.target.value)}
            required
            placeholder="例如: 10"
            min="1"
          />
        </div>
        <div className="mb-4 sm:mb-0">
          <label className="block text-gray-700">價格</label>
          <input
            type="number"
            step="0.01"
            className="w-full px-3 py-2 border rounded mt-1 focus:outline-none focus:ring-2 focus:ring-blue-500"
            value={price}
            onChange={(e) => setPrice(e.target.value)}
            required
            placeholder="例如: 150.00"
            min="0.01"
          />
        </div>
      </div>
      <button
        type="submit"
        className="mt-4 w-full bg-green-500 text-white py-2 rounded hover:bg-green-600 transition duration-200"
      >
        新增交易
      </button>
    </form>
  );
};

export default TransactionForm;
